import DynamicEntry from '@xpedeo/core/dist/components/DynamicEntry'

export default DynamicEntry.extend({
  computed: {
    dynamicComponent () {
      switch (this.entryData.Class) {
        case 'Image':
          return () => import(/* webpackChunkName: "xp-image-entry" */ '@xpedeo/core/dist/components/entries/ImageEntry')
        case 'Video':
          return () => import(/* webpackChunkName: "xp-video-entry" */ '@xpedeo/core/dist/components/entries/VideoEntry')
        case 'Menu':
          return () => import(/* webpackChunkName: "xp-menu-entry" */ './entries/MenuEntry')
        case 'Html':
          // return () => import(/* webpackChunkName: "xp-html-entry" */ '@xpedeo/core/dist/components/entries/HtmlEntry')
          return () => import(/* webpackChunkName: "xp-html-entry" */ './entries/HtmlEntry')
        case 'Text':
          return () => import(/* webpackChunkName: "xp-text-entry" */ '@xpedeo/core/dist/components/entries/TextEntry')
        case 'Contact':
          return () => import(/* webpackChunkName: "xp-contact-entry" */ '@xpedeo/core/dist/components/entries/ContactEntry')
        case 'Entity':
          return () => import(/* webpackChunkName: "xp-entity-entry" */ '@xpedeo/core/dist/components/entries/EntityEntry')
        case 'Panorama':
        case 'XpPanoramaPage':
          return () => import(/* webpackChunkName: "xp-panorama-entry" */ '@xpedeo/core/dist/components/entries/PanoramaEntry')
        case 'AugmentedReality':
          return () => import(/* webpackChunkName: "xp-panorama-entry" */ '@xpedeo/core/dist/components/entries/ArEntry')
        case 'ThreeDeeModel':
          return () => import(/* webpackChunkName: "xp-threedeemodel-entry" */ '@xpedeo/core/dist/components/entries/ThreeDeeModelEntry')
        default:
          return 'div'
      }
    }
  }
})
